import ApiClient from "api/base/ApiClient";
import { AppointmentFeedbackStatus } from "api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { AppointmentFeedbackClientDto, FeedbackSource } from "api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import CurrentUser from "model/CurrentUser";
import AudioFileStorage from "../AudioFileStorage";
import { AppointmentFeedbackAudio, AppointmentFeedbackAudioStatus } from "api/vs-recording/model/Audio/AppointmentFeedbackAudio";
import { AppointmentFeedbackNote } from "api/vs-recording/model/Appointments/AppointmentFeedbackNote";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";

export default class AppointmentStore {
    apiClient: ApiClient;
    loading: boolean = false;
    savingDraft: boolean = false;
    savingNote: boolean = false;
    editingText: boolean = false;
    appointment: AppointmentClientDto | null = null;
    error: string | null = null;
    user: CurrentUser;
    isRecording: boolean = false;

    @observable audioUploading: boolean = false;
    @observable audioUploadingUri: string | null = null;
    @observable hasAudioErrors: boolean = false;

    constructor(apiClient: ApiClient, user: CurrentUser) {
        this.apiClient = apiClient;
        this.user = user;
        makeAutoObservable(this);
    }

    async load(id: number) {
        runInAction(() => {
            this.loading = true;
            this.error = null;
        });
        try {
            const appointment = await this.apiClient.vsRecording.appointment.get(id);
            runInAction(() => (this.appointment = appointment));
            if (!appointment?.feedback?.id) {
                await this.saveDraft();
            }
            await this.enrichWithExistingNotUploadedAudio();
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error loading the appointment. Please contact support.";
            });
        }
        runInAction(() => (this.loading = false));
    }

    async refresh() {
        if (this.appointment) {
            await this.load(this.appointment.id);
        }
    }

    get isEmpty() {
        if (!this.appointment) return true;
        const feedbackNotes = this.appointment.feedback?.notes;
        if (feedbackNotes) {
            if (feedbackNotes.some((x) => x.text && x.text.length > 0)) return false;
            if (feedbackNotes.some((x) => x.appointmentFeedbackAudio && x.appointmentFeedbackAudio.length > 0)) return false;
        }
        return !feedbackNotes || feedbackNotes.length === 0;
    }

    async prepareFeedback() {
        runInAction(() => {
            if (!this.appointment) return;
            if (!this.appointment.feedback) {
                this.appointment.feedback = {
                    ambientMode: true,
                    appointmentId: this.appointment.id,
                } as AppointmentFeedbackClientDto;
            }
            if (!this.appointment.feedback.notes) {
                this.appointment.feedback.notes = [];
            }
        });
    }

    setEditingText(isEditing: boolean) {
        this.editingText = isEditing;
    }

    async setTemplateId(id: number) {
        runInAction(() => {
            if (this.appointment?.feedback?.notes) {
                for (const note of this.appointment.feedback.notes) {
                    if (!note.isAmbient && note.templateFieldId !== null) {
                        for (const audio of note.appointmentFeedbackAudio || []) {
                            if (audio.uri) {
                                this.deleteAudioFromNote(audio, note);
                                AudioFileStorage.deleteAudios([audio]);
                            }
                        }
                        note.appointmentFeedbackAudio = [];
                    }
                }
            }
            this.loading = true;
            if (this.appointment?.feedback) {
                this.appointment.feedback.templateId = id;
            }
        });
        await this.saveDraft(true);
        runInAction(() => {
            this.loading = false;
        });
    }

    async setAmbientMode(ambient: boolean, save: boolean = false) {
        runInAction(() => {
            if (this.appointment?.feedback) {
                this.appointment.feedback.ambientMode = ambient;
            }
        });
        if (save) await this.saveDraft(true);
    }

    setIsRecording(isRecording: boolean) {
        this.isRecording = isRecording;
    }

    setError(error: string | null) {
        this.error = error;
    }

    get canBeDeleted() {
        return this.appointment?.manualCreated === true && (!this.appointment?.feedback || this.appointment.feedback.status === AppointmentFeedbackStatus.Draft);
    }

    async saveDraft(ignoreNotUploadedAudios: boolean = false): Promise<boolean> {
        if (!this.appointment) return false;
        let result = true;
        let notLoadedAudios: AppointmentFeedbackAudio[] = [];

        if (ignoreNotUploadedAudios && this.appointment.feedback?.notes) {
            this.appointment.feedback.notes.forEach(note => {
                note.appointmentFeedbackAudio?.forEach(audio => {
                    if (audio.uri && !audio.id) {
                        notLoadedAudios.push(audio);
                    }
                });
            });
        }

        for (const audio of notLoadedAudios) {
            const note = audio.appointmentFeedBackNoteId
                ? this.appointment.feedback.notes.find(x => x.id === audio.appointmentFeedBackNoteId)
                : null;
            await this.deleteAudioFromNote(audio, note || null, false);
        }

        runInAction(() => {
            this.savingDraft = true;
        });
        await this.prepareFeedback();
        try {
            const updated = await this.apiClient.vsRecording.appointment.saveDraft(this.appointment.feedback!);
            runInAction(() => {
                if (updated?.feedback?.id) {
                    this.appointment!.feedback!.id = updated.feedback.id;
                }

                this.appointment!.feedback!.notes = updated?.feedback?.notes || [];
                this.appointment!.feedback!.templateId = updated?.feedback?.templateId;
                this.appointment!.feedback!.template = updated?.feedback?.template;
                this.appointment!.feedback!.ambientMode = updated?.feedback?.ambientMode;
                this.appointment!.feedback!.status = updated?.feedback?.status || AppointmentFeedbackStatus.Draft;
                this.enrichWithExistingNotUploadedAudio();
                this.savingDraft = false;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error saving the recording. Please contact support.";
                this.savingDraft = false;
            });
            result = false;
        }
        return result;
    }

    async enrichWithExistingNotUploadedAudio() {
        if (this.appointment?.feedback?.notes) {
            for (const note of this.appointment.feedback.notes) {
                const noteAudios = await AudioFileStorage.getAudios(note.id);
                for (const audio of noteAudios) {
                    if (audio.id) continue;
                    const blob = await AudioFileStorage.getBlobByKey(audio.uri!);
                    if (blob) {
                        await this.addOrUpdateAudioToNote(audio.uri, audio.duration!, note, false);
                    }
                }
            }
        }
    }

    async completeFeedback() {
        if (this.hasAudioErrors) {
            runInAction(() => {
                this.error = "Error with uploading audio. Please try again or contact support.";
            });
            return;
        }
        if (!this.appointment) return;
        await this.prepareFeedback();
        let result = true;
        for (const note of this.appointment.feedback.notes) {
            if (note.needToSave || note.appointmentFeedbackAudio?.some((x) => x && x.id == null && !x.hasError)) {
                result = await this.saveNote(note);
            }
        }
        if (!result) return;
        runInAction(() => {
            this.appointment!.feedback!.source = FeedbackSource.Desktop;
        });
        result = await this.saveDraft();
        if (!result) return;
        const feedback = await this.apiClient.vsRecording.appointment.completeFeedback(this.appointment.feedback!);
        runInAction(() => {
            if (feedback) this.appointment!.feedback = feedback;
        });
        await this.load(this.appointment!.id);
    }

    async goBackToReSubmit() {
        if (!this.appointment) return;
        try {
            const feedbackEdit = await this.apiClient.vsRecording.appointment.goBackEditFeedback(this.appointment.feedback!);
            runInAction(() => {
                if (feedbackEdit) {
                    this.appointment!.feedback = feedbackEdit;
                }
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error with resubmitting feedback. Please contact support.";
            });
        }
    }

    async addNewNote() {
        if (!this.appointment) return;
        await this.prepareFeedback();
        const newNote = await this.apiClient.vsRecording.appointment.addNewNote(this.appointment.feedback!.appointmentId);
        runInAction(() => {
            if (newNote) {
                this.appointment!.feedback!.notes = [...this.appointment!.feedback!.notes, newNote];
            }
        });
    }

    async deleteAppointment() {
        if (!this.appointment?.manualCreated) return;
        if (this.appointment.feedback?.notes) {
            for (const note of this.appointment.feedback.notes) {
                if (note.appointmentFeedbackAudio) {
                    for (const audio of note.appointmentFeedbackAudio) {
                        if (audio.uri) {
                            await AudioFileStorage.deleteAudios([audio]);
                        }
                    }
                }
            }
        }
        await this.apiClient.vsRecording.appointment.deleteAppointment(this.appointment);
    }

    async uploadAudio(audio: AppointmentFeedbackAudio): Promise<AppointmentFeedbackAudio | null> {
        if (audio.uri == null) return null;
        let response: AppointmentFeedbackAudio | null = null;

        try {
            const attempt = !audio.uploadAttempt ? 1 : audio.uploadAttempt + 1;
            runInAction(() => {
                audio.isSaving = true;
                audio.uploadAttempt = attempt;
                this.audioUploading = true;
                this.audioUploadingUri = audio.uri;
            });

            const blob = await AudioFileStorage.getBlobByKey(audio.uri!);

            const linkData = await this.apiClient.vsRecording.appointment.uploadAudioLink(audio);
            runInAction(() => {
                audio.id = linkData.id;
            });

            console.log(`Starting upload for file: ${audio.uri}, uploadLink: ${linkData.link}`);

            await this.apiClient.vsRecording.appointment.uploadAudio(linkData.link, audio, blob);

            response = await this.apiClient.vsRecording.appointment.uploadAudioFinalize(audio);

            if (response != null && response?.status == AppointmentFeedbackAudioStatus.Uploaded) {
                await AudioFileStorage.deleteByKey(audio.uri);

                runInAction(() => {
                    audio.uploaded = true;
                    audio.id = response.id;
                });

                console.log(`Upload successful for file: ${audio.uri}`);

                return response;
            } else {
                throw new Error("Error: " + response);
            }
        } catch (e: any) {
            console.error("Error uploading the audio", e);
            runInAction(() => {
                audio.isSaving = false;
                audio.hasError = true;
                this.hasAudioErrors = true;
                this.error = "Error with uploading audio. Please try again or contact support.";
            });
            return null;
        } finally {
            runInAction(() => {
                this.audioUploading = false;
                this.audioUploadingUri = null;
            });
        }
    }

    async saveNote(note: AppointmentFeedbackNote, upload: boolean = true): Promise<boolean> {
        if (this.hasAudioErrors) {
            runInAction(() => {
                this.error = "Error with uploading audio. Please try again or contact support.";
            });
            return false;
        }
        let result = true;
        runInAction(() => {
            this.savingNote = true;
            this.error = null;
        });

        try {
            if (upload && note.appointmentFeedbackAudio) {
                for (let i = 0; i < note.appointmentFeedbackAudio.length; i++) {
                    const audio = note.appointmentFeedbackAudio[i];
                    if (!audio || audio.id || audio.isSaving || audio.hasError) continue;

                    const blob = await AudioFileStorage.getBlobByKey(audio.uri!);
                    if (!blob) {
                        console.error(`Blob not found for audio: ${audio.uri}`);
                        continue;
                    }

                    const uploadResponse = await this.uploadAudio(audio);

                    if (uploadResponse?.uploaded) {
                        runInAction(() => {
                            note.appointmentFeedbackAudio[i] = uploadResponse!;
                        });
                    }
                }
            }

            await this.apiClient.vsRecording.appointment.saveNote(note);
            runInAction(() => {
                note.needToSave = false;
                this.savingNote = false;
            });
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error saving the note. Please contact support.";
                this.savingNote = false;
            });
            result = false;
        }
        return result;
    }

    async addOrUpdateAudioToNote(
        uri: string,
        duration: number,
        note: AppointmentFeedbackNote,
        isInProgress: boolean = false,
    ) {
        const audio: AppointmentFeedbackAudio = {
            uri,
            appointmentFeedBackNoteId: note.id,
            duration: duration,
            isInProgress: isInProgress,
            id: null,
            uploadAttempt: 0,
            isSaving: false,
            appointmentFeedBackId: note.appointmentFeedBackId,
        };

        runInAction(() => {
            if (!note.appointmentFeedbackAudio) {
                note.appointmentFeedbackAudio = [];
            }
            const existingAttachedIndex = note.appointmentFeedbackAudio.findIndex(x => x.uri === audio.uri);
            if (existingAttachedIndex > -1) {
                note.appointmentFeedbackAudio[existingAttachedIndex] = { ...audio };
            } else {
                if (!audio.isInProgress)
                    note.appointmentFeedbackAudio.push(audio);
            }
        });

        runInAction(() => {
            this.appointment!.feedback!.notes = this.appointment!.feedback!.notes.map(n => {
                if (n.id === note.id) {
                    return { ...n, appointmentFeedbackAudio: note.appointmentFeedbackAudio };
                }
                return n;
            });
        });
    }

    async deleteAudioFromNote(audio: AppointmentFeedbackAudio, note: AppointmentFeedbackNote, deleteFromStorage: boolean = true) {
        if (audio.id) {
            await this.apiClient.vsRecording.appointment.deleteAudio(audio);
        }
        runInAction(() => {
            if (!note.appointmentFeedbackAudio) return;
            const index = note.appointmentFeedbackAudio.indexOf(audio);
            if (index > -1) {
                if (deleteFromStorage == true) AudioFileStorage.deleteAudios([audio]);
                note.appointmentFeedbackAudio.splice(index, 1);
            }
        });
    }

    async rateFeedBack(feedbackId: number, rating: number) {
        runInAction(() => {
            if (this.appointment?.feedback) {
                this.appointment.feedback.rating = rating;
            }
        });
        await this.apiClient.vsRecording.appointment.rateFeedBack(feedbackId, rating);
    }

    async deleteNote(note: AppointmentFeedbackNote) {
        if (!note.id) return;
        runInAction(() => {
            const found = this.appointment?.feedback?.notes.indexOf(note);
            if (typeof found !== "undefined" && found > -1) {
                this.appointment!.feedback!.notes = this.appointment!.feedback!.notes.filter((x) => x.id !== note.id);
                this.apiClient.vsRecording.appointment.deleteNote(note);
            }
        });
    }

    async feedbackAllChecked(feedback: AppointmentFeedbackClientDto) {
        await this.apiClient.vsRecording.appointment.doneAllChecked(feedback);
        await this.load(feedback.appointmentId);
    }

    async feedbackStartEdit(feedback: AppointmentFeedbackClientDto) {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const res = await this.apiClient.vsRecording.appointment.startEdit(feedback);
            if (res?.success && res?.data) {
                runInAction(() => {
                    if (this.appointment && res.data) {
                        this.appointment.feedback = res.data;
                    }
                });
            } else {
                throw new Error(res?.error || "Error while initiate editing");
            }
            return res;
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error starting feedback edit. Please contact support.";
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async feedbackCancelEdit(feedback: AppointmentFeedbackClientDto) {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const res = await this.apiClient.vsRecording.appointment.cancelEdit(feedback);
            if (res?.success && res.data) {
                runInAction(() => {
                    if (this.appointment && res.data) {
                        this.appointment.feedback = res.data;
                    }
                });
            } else {
                throw new Error(res?.error || "Error while cancelling editing");
            }
            return res;
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error cancelling feedback edit. Please contact support.";
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async feedbackSaveEdit(feedback: AppointmentFeedbackClientDto) {
        try {
            runInAction(() => {
                this.loading = true;
            });
            const res = await this.apiClient.vsRecording.appointment.saveEdit(feedback);
            if (res?.success && res.data) {
                runInAction(() => {
                    if (this.appointment && res.data) {
                        this.appointment.feedback = res.data;
                    }
                });
            } else {
                throw new Error(res?.error || "Error while save editing");
            }
            return res;
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error saving feedback edit. Please contact support.";
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async addToPIMS(added: boolean) {
        if (!this.appointment) return;
        try {
            runInAction(() => {
                this.loading = true;
            });
            const res = await this.apiClient.adminFeedback.addedToPims(this.appointment.feedback?.id, !added);
            if (res) {
                runInAction(() => {
                    if (this.appointment && res) {
                        this.appointment.feedback.addedToPims = res.addedToPims;
                    }
                });
            } else {
                throw new Error("Error while adding to PIMS");
            }
            return res;
        } catch (e: any) {
            runInAction(() => {
                this.error = "Error adding to PIMS. Please contact support.";
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}

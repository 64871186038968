import { CheckOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Button, Card, Col, Divider, Drawer, Dropdown, Modal, Popconfirm, Row, Tag, Tooltip, notification } from "antd";
import { AppointmentFeedbackReviewTier, AppointmentFeedbackReviewTierList } from "api/model/Appointments/enums/AppointmentFeedbackReviewTier";
import { AppointmentFeedbackStatus, AppointmentFeedbackStatusList } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { SAASUserRoles, UserRoleEnum } from "api/model/User/UserRole";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AdminFeedbackUpdateChatDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateChatDto";
import { tryParseInt } from "misc/helpers/intExtensions";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useVSHub from "misc/hooks/useVSHub";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppointmentView from "routes/appointment/AppointmentView";
import FeedbackHistory from "./History/FeedbackHistory";
import MessageList from "./Messages/MessageList";
import FeedbackEdit from "./com/FeedbackEdit";
import FeedbackOutput from "../../app/components/FeedbackOutputView/FeedbackOutput";
import FeedbackRecordings from "./com/FeedbackRecordings";
import useDrawer from "misc/hooks/useDrawer";
import { LanguageEnum } from "api/model/User/Language";
import FeedbackVisitSummary from "app/components/FeedbackVisitSummaryView/FeedbackVisitSummary";


const FeedbackView: React.FC = observer(() => {
	const { id: feedbackId } = useParams();

	const api = useApi();
	const navigate = useNavigate();

	const [state, setState] =
		useState<{ isLoading: boolean, error: string | null, feedback: AdminFeedbackDto | null }>
			({ isLoading: true, error: null, feedback: null });

	const hub = useVSHub();

	const [edit, setEdit] = useState<boolean>(false);
	const user = useCurrentUser();
	const outputModal = useDrawer<number>();
	const visitSummaryModal = useDrawer<number>();
	const historyDrawer = useDrawer();

	useEffect(() => {
		loadFeedback();
	}, [feedbackId])

	useEffect(() => {
		if (!hub.connected) return;

		hub.subscribe(`admin-feedback-updates-${feedbackId}`);
		hub.on<string>(`update-feedback-${feedbackId}`, updateFeedback)
		hub.localSubscribe<string>(`update-chat`, updateFeedbackChat);


		return () => {
			hub.localUnsubscribe(`update-chat`, updateFeedbackChat);
			hub.unsubscribe(`admin-feedback-updates-${feedbackId}`);
			hub.off<string>(`update-feedback-${feedbackId}`)
		}
	}, [hub.connected]);


	useEffect(() => {
		if (edit) return;
		loadFeedback();
	}, [edit])

	const updateFeedback = async (fb: string) => {
		var res = JSON.parse(fb) as AdminFeedbackDto;
		if (state.feedback?.id == res.id)
			setState(prev => {
				return { feedback: { ...prev.feedback, ...res }, isLoading: false, error: null };
			});
	}

	const updateFeedbackChat = async (fbChatData: string) => {
		var res = JSON.parse(fbChatData) as AdminFeedbackUpdateChatDto;
		if (state.feedback?.id == res.id)
			setState(prev => {
				return { feedback: { ...prev.feedback, ...res }, isLoading: false, error: null };
			});
	}

	const loadFeedback = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.get(tryParseInt(feedbackId));
			if (res.success) {
				setState({ ...state, feedback: res.data, isLoading: false });
			} else {
				notification.error({ message: res.error });
				setState({ ...state, isLoading: false })
			}
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const completeEditing = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.MarkupDone);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const completeRecording = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.Completed);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}


	const sendToTier = async (tier: AppointmentFeedbackReviewTier) => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.sendToTier(state.feedback, tier);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const toggleAddedToPims = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.addedToPims(state.feedback?.id, !state.feedback.addedToPims);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const returnToMarkup = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.Markup);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const deleteFeedback = async () => {
		if (!state.feedback.id) return;
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.delete(state.feedback.id);
			navigate("/recordings");
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const closeChat = async () => {
		if (!state.feedback.id) return;
		setState({ ...state, isLoading: true, error: null })
		try {
			await api.adminFeedback.closeChat(state.feedback.id);
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const reProcess = async () => {
		if (!state.feedback.id) return;
		setState({ ...state, isLoading: true, error: null })
		try {
			await api.adminFeedback.reProcess(state.feedback.id);
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
		notification.success({ message: 'Refresh the page' });
	}

	const changeLanguage = async (lang: LanguageEnum) => {
		if (!state.feedback.id) return;
		setState({ ...state, isLoading: true, error: null })
		try {
			await api.adminFeedback.changeLanguage(state.feedback.id, lang);
			setState({ ...state, isLoading: false });
			// window.location.reload();
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({
				...state, isLoading: false
			})
		}
	}

	const isOwner = state.feedback && user.doctorIds.includes(state.feedback.appointment.doctorId)
	const isPowerAdmin = user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Vet, ...SAASUserRoles]);

	const hasAccess = isPowerAdmin || isOwner;

	const showEditButton = state.feedback &&
		(state.feedback.status == AppointmentFeedbackStatus.Markup || state.feedback.status == AppointmentFeedbackStatus.MarkupDone) ||
		(
			state.feedback?.status == AppointmentFeedbackStatus.Completed &&
			user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor])
		);

	const disableEditButton = state.feedback &&
		state.feedback.editorId != user.id &&
		((state.feedback.isEditing && !hasAccess) ||
			(state.feedback.reviewTier != AppointmentFeedbackReviewTier.Scribe && !hasAccess));

	const showCompleteEditingButton = state.feedback && (state.feedback.status == AppointmentFeedbackStatus.Markup) && hasAccess && !user.isSAASUser;
	let showCompleteButton = state.feedback &&
		((state.feedback.status == AppointmentFeedbackStatus.Markup && (user.isSAASUser)) || state.feedback.status == AppointmentFeedbackStatus.MarkupDone) &&
		hasAccess;


	let showReturnToMarkupButton = state.feedback &&
		(state.feedback.status == AppointmentFeedbackStatus.MarkupDone || state.feedback.status == AppointmentFeedbackStatus.Completed) && hasAccess;

	if (user.isSAASUser) {
		showReturnToMarkupButton = state.feedback && (state.feedback.status == AppointmentFeedbackStatus.Completed) && hasAccess;
	}

	const feedBackStatusMeta = AppointmentFeedbackStatusList.find(x => x.value == state.feedback?.status);
	const showChat = state.feedback && (!state.feedback.appointment.accountId && isPowerAdmin) || user.isInRole([UserRoleEnum.Scribe]);

	const canBeReprocessed = user.isSuperAdmin && state.feedback &&
		![AppointmentFeedbackStatus.ReadyToProcess, AppointmentFeedbackStatus.Processing, AppointmentFeedbackStatus.ErrorProcessing].includes(state.feedback.status);

	const cardContent = () => {
		const canManageChat = user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Scribe]);

		if (state.error) {
			<Alert message={state.error} />
		}

		if (state.feedback)
			return <Row gutter={[10, 10]}>
				<Col xl={12} lg={24}>
					<Card title="Appointment Information" style={{ marginBottom: "10px" }} >
						<AppointmentView appointment={state.feedback.appointment} onLangChange={lang => changeLanguage(lang)} />
					</Card>

					<Card title="Recordings" extra={<>
						{state.feedback.ambientMode && <Tag color="blue">AMBIENT MODE</Tag>}
						<Tooltip title={feedBackStatusMeta.tooltip} placement="left">
							<Tag color={feedBackStatusMeta.color}>{feedBackStatusMeta.label.toUpperCase()}</Tag>
						</Tooltip>
						<Button type='default' size={"small"} onClick={() => historyDrawer.open()}>History</Button>
						{canBeReprocessed &&
							<Popconfirm title="Are you sure you want to re-process this recording? All your edits will be removed." onConfirm={reProcess}>
								<Button type={"default"} size={"small"} className='ml-5'>Re-process</Button>
							</Popconfirm>
						}
					</>
					}>
						<FeedbackRecordings feedback={state.feedback} />
					</Card>
				</Col>
				{showChat &&
					<Col xl={12} lg={24}>
						<Card title="Chat" extra={
							state.feedback.chatOpen ?
								<>
									<Tag color='blue'>OPEN</Tag>
									{canManageChat &&
										<Popconfirm
											title={"Are you sure you want to close the chat? Doctor will be able to open it"}
											okText={"Close"}
											onConfirm={closeChat}>
											<Button type={"primary"}>Close Chat</Button>
										</Popconfirm>
									}
								</>
								:
								<>
									<Tag color='default'>CLOSED</Tag>
								</>
						}>
							<MessageList feedback={state.feedback} />
						</Card>
					</Col>
				}
			</Row >
	}

	const buttons = () => {
		if (!state.feedback) return null;
		return <div className="feedback-btns" id="feedback-btns-container">
			{!user.isSAASUser && !user.isRestrictedUser && ReviewStageBtn()}

			<Button type={"default"} onClick={() => historyDrawer.open()}>History</Button>

			{showEditButton && <Button disabled={disableEditButton} type={"primary"} onClick={() => setEdit(true)}>Edit Recording</Button>}

			{showCompleteEditingButton &&
				<Popconfirm title="Are you sure?" onConfirm={completeEditing}>
					<Button type={"primary"}>Complete Editing</Button>
				</Popconfirm>}

			{showCompleteButton &&
				<Popconfirm title="Are you sure?" onConfirm={completeRecording}>
					<Button type={"primary"}>Complete</Button>
				</Popconfirm>}


			{showReturnToMarkupButton && <Popconfirm title="Are you sure?" onConfirm={returnToMarkup}>
				<Button type={"primary"}>Return to Editing</Button>
			</Popconfirm>}

			<Button type={"primary"} onClick={() => outputModal.open(state.feedback?.id)}>Output</Button>
			<Button type={"primary"} onClick={() => visitSummaryModal.open(state.feedback?.id)}>Summary</Button>

			<Popconfirm title={!state.feedback.addedToPims ? "Are you sure?" : "Remove from PIMS?"} onConfirm={toggleAddedToPims}>
				{state.feedback.addedToPims ?
					<Button type={"primary"} icon={<CheckOutlined />}>Remove from PIMS</Button> :
					<Button type={"default"} icon={<CheckOutlined />}>Add to PIMS</Button>
				}
			</Popconfirm>


			{user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.AccountManager, UserRoleEnum.AccountOwner]) &&
				<Popconfirm title={"Are you sure you want to remove this recording? This action cannot be undone."} onConfirm={deleteFeedback}>
					<Button type={"primary"} danger >Delete Recording</Button>
				</Popconfirm>
			}
		</div>
	}

	const ReviewStageBtn = () => {
		const currentStage = state.feedback.reviewTier;
		const { label, color } = AppointmentFeedbackReviewTierList.find(x => x.value == currentStage);

		const items = [
			{
				disabled: currentStage == AppointmentFeedbackReviewTier.Scribe,
				key: AppointmentFeedbackReviewTier.Scribe.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Scribe)}> To Scriber </a>
			},
			{
				disabled: currentStage == AppointmentFeedbackReviewTier.Supervisor,
				key: AppointmentFeedbackReviewTier.Supervisor.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Supervisor)}> To Supervisor </a>
			},

		];


		if (user.isInRole([UserRoleEnum.Supervisor, UserRoleEnum.SuperAdmin, UserRoleEnum.Vet])) {
			items.push({
				disabled: currentStage == AppointmentFeedbackReviewTier.Vet,
				key: AppointmentFeedbackReviewTier.Vet.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Vet)}> To Vet </a>
			})
		}


		return <Dropdown overlayStyle={{ width: 200 }} placement="top" arrow menu={{ items }} trigger={["click"]} getPopupContainer={trigger => trigger.parentElement}>
			<Button loading={state.isLoading}>
				@<b>{label}</b>
				<div className={`dot ${color} ml-10`} />
			</Button>
		</Dropdown >

	}

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="/">Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				<Link to="/recordings" >Recordings</Link>
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				{state.feedback && moment(state.feedback.createdDate).format("DD MMM yyyy hh:mma")}
				<Divider type="vertical" />
				{state.feedback && "Doctor: " + state.feedback.appointment?.doctorName}
			</Breadcrumb.Item>
		</Breadcrumb>
		<Divider />
		{cardContent()}
		{buttons()}

		{showEditButton ?
			<Modal title={"Editing of recording"} footer={false} bodyStyle={{ padding: 0 }} width={1000} onCancel={() => setEdit(false)} open={edit} destroyOnClose={true} closable={true} maskClosable={false}>
				<FeedbackEdit feedbackId={state.feedback.id} onClose={() => setEdit(false)} />
			</Modal> : null}

		{state.feedback?.id && <Drawer width={1000}
			onClose={() => historyDrawer.close()}
			title={"History"}
			open={historyDrawer.visible}
			destroyOnClose={true}
			closable={true}
			maskClosable={true}>
			<FeedbackHistory feedbackId={state.feedback.id} />
		</Drawer>}

		<Modal open={outputModal.visible}
			width={850}
			onOk={() => outputModal.close()}
			onCancel={() => outputModal.close()}
			title={"Output"}
			okText="Close"
			cancelButtonProps={{ style: { display: 'none' } }} destroyOnClose={true} closable={true} maskClosable={true}>
			{<FeedbackOutput id={outputModal.data!} />}
		</Modal>

		<Modal open={visitSummaryModal.visible}
			width={850}
			onOk={() => visitSummaryModal.close()}
			onCancel={() => visitSummaryModal.close()}
			title={"Visit Summary"}
			okText="Close"
			cancelButtonProps={{ style: { display: 'none' } }} destroyOnClose={true} closable={true} maskClosable={true}>
			{<FeedbackVisitSummary id={visitSummaryModal.data!} />}
		</Modal>

	</>
})


export default FeedbackView;



import { Select, Skeleton } from "antd";
import { Doctor } from "api/model/Offices/Doctor";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useEffect, useState } from "react";

const { Option, OptGroup } = Select;

type DoctorSelectProps = {
    value?: number;
    defaultValue?: number;
    groupByPractice?: boolean;
    className?: string;
    style?: React.CSSProperties;
    allowClear?: boolean;
    onChange: (x: any) => void;
    getDoctor?: (doctor: Doctor) => void;
};

const DoctorPracticeSelect: React.FC<DoctorSelectProps> = ({ value, defaultValue, groupByPractice, className, style, allowClear, onChange, getDoctor }) => {
    const api = useApi();
    const [items, setItems] = useState<Doctor[]>([]);
    const [search, setSearch] = useState("");
    const user = useCurrentUser();
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);

    if (user.isRestrictedUser) return null;

    useEffect(() => {
        load();
    }, [search]);

    useEffect(() => {
        if (defaultValue && items.length > 0) {
            const doctor = items.find(doctor => doctor.id === defaultValue);
            if (doctor) {
                onChange(doctor.id);
                if (getDoctor) {
                    getDoctor(doctor);
                }
            }
            setInitialLoading(false);
        }
    }, [defaultValue, items]);

    const load = async () => {
        setLoading(true);
        const res = await api.adminDoctor.list({
            page: 1,
            includeIds: user.doctorIds,
            pageSize: 20,
            search: search,
            isRemoved: false,
            sortField: "LastName",
            sortDirection: "asc"
        });
        setItems(res.data);
        setLoading(false);
    }

    const groupByPracticeData = () => {
        const groups: { [key: string]: Doctor[] } = {};
        items.forEach(doctor => {
            const practiceName = doctor.practice?.name || "Unknown Practice";
            if (!groups[practiceName]) {
                groups[practiceName] = [];
            }
            groups[practiceName].push(doctor);
        });
        return groups;
    }

    if (loading) return <Skeleton.Input active />;
    return <Select
        placeholder="Doctor"
        style={style ?? { width: 150 }}
        loading={loading || initialLoading}
        disabled={loading || initialLoading}
        allowClear={allowClear}
        value={value}
        defaultValue={defaultValue}
        onChange={(x) => {
            onChange(x)
            if (getDoctor) {
                const selectedDoctor = items.find(doctor => doctor.id === x);
                getDoctor(selectedDoctor);
            }
        }}
        onSearch={setSearch}
        showSearch
        filterOption={false}
        className={className}>
        {groupByPractice
            ? Object.entries(groupByPracticeData()).map(([practiceName, doctors]) => (
                <OptGroup key={practiceName} label={practiceName}>
                    {doctors.map(doctor => (
                        <Option key={doctor.id} value={doctor.id}>
                            {doctor.lastName} {doctor.firstName}
                        </Option>
                    ))}
                </OptGroup>
            ))
            : items.map(doctor => (
                <Option key={doctor.id} value={doctor.id}>
                    {doctor.lastName} {doctor.firstName}
                </Option>
            ))}
    </Select>;
}

export default DoctorPracticeSelect;

import { Button, notification, Skeleton, Space } from "antd";
import { TemplateFieldType } from "api/model/Template/TemplateField";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CopiableDiv from "../CopiableDiv/CopiableDiv";
import useApi from "misc/hooks/useApi";
import { AdminFeedbackNoteDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackNoteDto";
import { extractTextWithLineBreaks } from "misc/helpers/extractText";

const FeedbackOutput: React.FC<{ id: number }> = observer(({ id }) => {
    const outputRef = useRef<HTMLDivElement>(null);
    const api = useApi();
    const [feedback, setFeedback] = useState<AdminFeedbackDto | null>(null);

    const fetchFeedback = useCallback(async () => {
        const res = await api.adminFeedback.get(id);
        if (res) setFeedback(res.data);
        else notification.error({ message: 'Failed to fetch feedback' });
    }, [id]);

    useEffect(() => {
        fetchFeedback();
    }, [fetchFeedback]);

    const templateNotes = useMemo(() => {
        if (!feedback) return [];
        const notesWithTemplate = feedback.notes.filter(x => x.templateFieldId != null);
        return notesWithTemplate.sort((a, b) => a.position - b.position);
    }, [feedback]);

    const customNotes = useMemo(() => {
        if (!feedback) return [];
        const notesWithoutTemplate = feedback.notes.filter(x => x.templateFieldId == null && !x.isAmbient);
        return notesWithoutTemplate.sort((a, b) => a.position - b.position);
    }, [feedback]);

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const textToCopy = extractTextWithLineBreaks(tempDiv);

        await navigator.clipboard.writeText(textToCopy);
        notification.success({ message: 'Output is copied to clipboard' });

    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);

        notification.success({ message: 'Output is copied to clipboard' });
    }, [outputRef.current]);

    const applyTextStyle = (text: string, isDefaultText: boolean, isBold: boolean, isUppercase: boolean) => {
        const splitText = (text?: string) => {
            if(text == null) return null;
            return text.split('\n').map((line, index) => <span key={index}>{line}<br /></span>);
        };

        const formattedText = splitText(text);
        return <span style={{
            color: isDefaultText ? '#999' : '#000',
            fontWeight: isBold ? '600' : '400',
            textTransform: isUppercase ? 'uppercase' : 'none'
        }}>
            {formattedText}
        </span>;
    };

    const renderNotes = (notes: AdminFeedbackNoteDto[]) => {
        const output = [];
        let section: JSX.Element[] = [];
        let sectionName = '';
        let hasSections = false;
        const noteClass = "mb-14 mt-14";

        notes.forEach(note => {
            const isDefaultText = note.text == note.templateField?.defaultValue;
            const isBold = !isDefaultText && note.templateField?.isBold;
            const isUppercase = !isDefaultText && note.templateField?.isUppercase;

            const styledText = applyTextStyle(note.text, isDefaultText, isBold, isUppercase);

            if (note.templateField?.type === TemplateFieldType.Separator) {
                hasSections = true;
                if (section.length > 0) {
                    output.push(
                        <>
                            {sectionName && <h3><b><u>{sectionName}</u></b></h3>}
                            <CopiableDiv key={sectionName} className="mb-10" buttonStyle={{ top: '-45px' }}>
                                {section}
                            </CopiableDiv>
                        </>
                    );
                    section = [];
                }
                sectionName = note.templateField.label;
            } else if(note.text && note.text.length > 0){
                if (hasSections) {
                    section.push(
                        <div key={note.id} className={noteClass}>
                            <b>{note.name && `${note.name}: `}</b>
                            {styledText}
                        </div>
                    );
                } else  {
                    output.push(
                        <div key={note.id} className={noteClass}>
                            <b>{note.name && `${note.name}: `}</b>
                            <CopiableDiv key={note.name} mode='inline'>{styledText}</CopiableDiv>
                        </div>
                    );
                }
            }
        });

        if (section.length > 0) {
            output.push(
                <>
                    {sectionName && <h3><b><u>{sectionName}</u></b></h3>}
                    <CopiableDiv key={sectionName} className="mb-10" buttonStyle={{ top: '-45px' }}>
                        {section}
                    </CopiableDiv>
                </>
            );
        }

        return output;
    };

    const getOutput = useCallback(() => {
        const templateOutput = renderNotes(templateNotes);
        const customOutput = renderNotes(customNotes);

        return [...templateOutput, ...customOutput];
    }, [templateNotes, customNotes]);

    if (!feedback) return <Skeleton active />;

    return <>
        <Space>
            <Button onClick={() => copyHtmlToClipboard(outputRef.current?.innerHTML || '')} style={{ marginBottom: 10 }}>Copy w/ formatting</Button>
            <Button onClick={() => copyTextToClipboard(outputRef.current?.innerHTML || '')} style={{ marginBottom: 10 }}>Copy as text</Button>
        </Space>
        <div ref={outputRef}>
            {getOutput()}
            <div style={{ marginTop: 20 }}>Recording is made by <b>{feedback.appointment.doctorName.trim()}</b>. Notes completed by VetSkribe.</div>
        </div>
    </>;
});

export default FeedbackOutput;

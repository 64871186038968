import { Button, Form, Input, Select, Skeleton, Typography } from "antd";
import { LanguageEnumList } from "api/model/User/Language";
import { ClientSimple } from "api/vs-recording/model/Clients/ClientSimple";
import { PatientSimple } from "api/vs-recording/model/Clients/PatientSimple";
import { AppointmentCreateDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto";
import { PracticeDto } from "api/vs-recording/routes/Appointment/DTO/PracticeDto";
import DatePicker from "app/components/DatePicker";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DoctorPracticeSelect from "vs-recording/components/Recording/components/DoctorPracticeSelect/DoctorPracticeSelect";

type AppointmentFormProps = {
    appointment: Partial<AppointmentCreateDto>;
    currentDate?: moment.Moment;
    onCancel: () => void;
    onDone: (appointment: AppointmentCreateDto) => void;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({ appointment: _appointment, currentDate, onCancel, onDone }) => {
    const api = useApi();
    const [appointment, setAppointment] = useState<Partial<AppointmentCreateDto>>(_appointment ?? {} as Partial<AppointmentCreateDto>);
    const [form] = Form.useForm();
    const [practices, setPractices] = useState<PracticeDto[]>([]);
    const [ready, setReady] = useState(false);
    const [existingPatient, setExistingPatient] = useState<PatientSimple | null>(null);
    const [existingClient, setExistingClient] = useState<ClientSimple | null>(null);
    const [patientManual, setPatientManual] = useState(false);
    const [clientManual, setClientManual] = useState(false);
    const user = useCurrentUser();

    useEffect(() => {
        init();
    }, []);

    const nearest15Minutes = (date: moment.Moment) => {
        let momentDate = moment(date);

        const minutes = momentDate.minutes();
        const roundedMinutes = Math.ceil(minutes / 15) * 15;

        if (roundedMinutes === 60)
            momentDate.add(1, 'hour').minutes(0);
        else
            momentDate.minutes(roundedMinutes);

        momentDate.set({
            second: 0,
            millisecond: 0,
        });

        return momentDate;
    };

    useEffect(() => {
        if (ready && existingClient)
            form.setFieldsValue({
                clientName: existingClient.firstName,
            });

        if (ready && existingPatient)
            form.setFieldsValue({
                patientName: existingPatient.name,
                patientSpecies: existingPatient.species,
            });

    }, [existingClient, existingPatient, ready]);

    const onAppointmentChange = useCallback((changes: Partial<AppointmentCreateDto>) => {
        setAppointment((prev) => ({ ...prev, ...changes }));
    }, []);

    const init = useCallback(async () => {
        const practiceList = await api.vsRecording.appointment.practiceList();
        setPractices(practiceList);

        if (practiceList.length === 0 && !user.isVetTech) {
            console.error("No practices found. Please contact support.");
            return;
        }

        const isNew = !appointment.id;

        if (isNew) {
            setAppointment({
                ...appointment
            });

        } else {
            setAppointment({
                ...appointment,
                date: appointment.date ? new Date(appointment.date) : new Date()
            });

            checkIfManual(appointment.clientId!, 'client');
            checkIfManual(appointment.patientId!, 'patient');

            if (practiceList[0]?.id) {
                form.setFieldsValue({ practiceId: appointment.practiceId });
            }
        }

        setReady(true);
    }, [appointment]);

    const checkIfManual = useCallback((id: string, target: 'client' | 'patient') => {
        switch (target) {
            case 'client':
                setClientManual(id.startsWith('cl-'));
                break;
            case 'patient':
                setPatientManual(id.startsWith('pa-'));
                break;
        }
    }, []);

    const checkPatient = useCallback(async (practiceId: number, id?: string | null) => {
        setExistingPatient(null);
        const existing = await api.vsRecording.appointment.checkPatient(id, practiceId);

        if (existing.success)
            setExistingPatient(existing.data);

    }, []);

    const checkClient = useCallback(async (practiceId: number, id?: string | null) => {
        setExistingClient(null);
        const existing = await api.vsRecording.appointment.checkClient(id, practiceId);

        if (existing.success)
            setExistingClient(existing.data);

    }, []);

    const submit = useCallback(() => {
        form.validateFields().then((values) => {
            onDone({ id: appointment?.id, practiceId: appointment?.practiceId, ...values } as AppointmentCreateDto);
        });
    }, [onDone, appointment]);

    const getInitialValues = useCallback(() => {
        return {
            ...appointment,
            doctorId: appointment.doctorId || user.doctorIds?.[0],
            date: currentDate ? nearest15Minutes(currentDate) : moment(appointment.date),
            language: appointment.language != null ? appointment.language : user.preferredLanguage,
        };
    }, [appointment, user]);

    const handlePatientIdChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        await checkPatient(appointment.practiceId!, value);
        onAppointmentChange({ patientId: value });
    };

    const handleClientIdChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        await checkClient(appointment.practiceId!, value);
        onAppointmentChange({ clientId: value });
    };

    if (!ready) return <Skeleton active />;
    return <div>
        <Form form={form} layout='vertical' initialValues={getInitialValues()} onFinish={submit} >
            {/* hack with display none to make getDoctor work */}
            <Form.Item name='doctorId' style={{ display: (user.doctorIds?.length <= 1 && !user.isVetTech) && 'none' }} label="Doctor" rules={[{ required: user.isVetTech, message: "Please select a doctor" }]} required>
                <DoctorPracticeSelect
                    practiceIds={!user.isSaasVetTech ? user.practiceIds : undefined}
                    groupByPractice
                    value={appointment.doctorId}
                    onChange={(x) => onAppointmentChange({ doctorId: x })}
                    getDoctor={doc => {
                        if (user.isVetTech && !doc) return;
                        onAppointmentChange({ practiceId: doc?.practiceId });
                    }}
                    style={{ width: 250 }} />
            </Form.Item>

            <Form.Item name='date' label="Appointment Date/Time">
                <DatePicker allowClear={false} showTime use12Hours showSecond={false} style={{ width: 250 }} onChange={x => onAppointmentChange({ date: x?.toDate() })} />
            </Form.Item>
            <Typography.Title level={4}>Patient</Typography.Title>
            <Typography.Paragraph>Enter PatientId to find existing patient or simplify future data entry</Typography.Paragraph>
            {!appointment?.patientId?.startsWith('pa_') && <Form.Item name='patientId'>
                <Input placeholder="PatientId" onChange={handlePatientIdChange} />
            </Form.Item>}
            {existingPatient && <Typography.Text style={{ fontSize: 12, marginTop: 5 }}>
                {"Patient found: "}
                <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>{existingPatient.name}</Typography.Text>
            </Typography.Text>}
            {!patientManual && !appointment.id && !existingPatient && <Typography.Text style={{ fontSize: 12, marginTop: 5 }}>New Patient will be created</Typography.Text>}
            <Form.Item name='patientName' required rules={[{ required: true, message: 'Patient name is required.' }]}>
                <Input placeholder="Patient Name" required
                    disabled={!!existingPatient}
                />
            </Form.Item>
            <Form.Item name='patientSpecies'>
                <Input placeholder="Patient Species"
                    disabled={!!existingPatient}
                />
            </Form.Item>
            <Typography.Title level={4}>Owner</Typography.Title>
            <Typography.Paragraph>Enter OwnerId to find existing owner or simplify future data entry. If ID not entered, it will be generated automatically</Typography.Paragraph>
            {!appointment?.clientId?.startsWith('cl_') && <Form.Item name='clientId'>
                <Input placeholder="Owner ID" onChange={handleClientIdChange} />
            </Form.Item>}
            {existingClient && <Typography.Text style={{ fontSize: 12, marginTop: 5 }}>
                {"Owner found: "}
                <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>{existingClient.firstName} {existingClient.lastName}</Typography.Text>
            </Typography.Text>}
            {!clientManual && !appointment.id && !existingClient && <Typography.Text style={{ fontSize: 12, marginTop: 5 }}>New Owner will be created</Typography.Text>}
            <Form.Item name='clientName'>
                <Input placeholder="Owner Name"
                    disabled={!!existingClient}
                />
            </Form.Item>

            <Typography.Title level={4}>Additional Settings</Typography.Title>


            <Form.Item name='language' label="Language">
                <Select placeholder="Select language (optional)">
                    {LanguageEnumList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
                </Select>
            </Form.Item>
        </Form>
        <footer className="flex align-center justify-between">
            <Button onClick={onCancel} type='default'>Cancel</Button>
            <Button onClick={() => form.submit()}>{appointment?.id ? 'Save' : 'Create'}</Button>
        </footer>
    </div>;
}

export default observer(AppointmentForm);

import { Alert, Breadcrumb, Divider, Space, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import { Link } from "react-router-dom";
import React from "react";
import { AccountStore } from "./AccountStore";
import useGeneralState from "misc/hooks/useGeneralState";
import { Account } from "api/model/Account/Account";
import moment from "moment";


const AccountList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<AccountStore>(() => new AccountStore(api));
	const generalState = useGeneralState();

	const columns: ColumnsType<Account> = [
		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: false,
			render: (x: Account) => <>
				{moment(x.createdDate).format("LLL")}
			</>
		},
		{
			title: 'Account',
			key: 'Account',
			sorter: false,
			render: (x: Account) => <>
				<Tag>{x.id}</Tag> {x.name}
			</>
		},
		{
			title: 'Trial',
			align: "right",
			render: (x: Account) => {
				var ended = moment(x.billingEndTrial).isBefore(moment());
				if (ended) {
					<Tag color={"green"}>Trial Ended</Tag>
				}
				return <Tooltip title={moment(x.billingEndTrial).format("LLL")}>
					{moment(x.billingEndTrial).fromNow()}
				</Tooltip>
			}
		},
		{
			title: 'Billing',
			align: "right",
			render: (x: Account) => <>
				at <b>{x.billingMonthDay}</b> each mo <br />
				<b>${x.billingPerDoctor}</b> per doctor
			</>
		},
	];

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="" >Admin</Link></Breadcrumb.Item>
			<Breadcrumb.Item>
				Billing reports
			</Breadcrumb.Item>
		</Breadcrumb>

		<Divider />
		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					loading={store.loading}
					rowKey={x => x.id}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={false}
				/>
		}
	</>
})


export default AccountList;





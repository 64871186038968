import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { Button, Typography, Modal, notification, Divider, Skeleton, Tag, Popconfirm } from 'antd';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { AppointmentFeedbackStatus, getAppointmentFeedbackStatusLabel } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import Summary from './Summary/Summary';
import LoadingOverlay from 'app/components/LoadingOverlay/LoadingOverlay';
import useApi from 'misc/hooks/useApi';
import { COLORS } from 'vs-recording/styles/Colors';
import useDrawer from 'misc/hooks/useDrawer';
import FeedbackOutput from 'app/components/FeedbackOutputView/FeedbackOutput';
import { AdminFeedbackDto } from 'api/routes/Appointments/Feedback/DTO/AdminFeedbackDto';
import FeedbackVisitSummary from 'app/components/FeedbackVisitSummaryView/FeedbackVisitSummary';

const { Text } = Typography;

type SubmittedProps = {
    feedback: AppointmentFeedbackClientDto;
    onEdit: () => void;
    onBack: () => void;
    onResubmit: () => void;
};


export const Submitted: React.FC<SubmittedProps> = observer(({ feedback, onEdit, onBack, onResubmit }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const user = useCurrentUser();
    const outputModal = useDrawer<number>();
    const visitSummaryModal = useDrawer<number>();
    const statusData = getAppointmentFeedbackStatusLabel(feedback?.status);

    const approveFeedback = () => {
        Modal.confirm({
            title: 'Approve recording?',
            content: 'This means the recording is ready to be inserted in PIMS',
            onOk: () => appointmentContext?.feedbackAllChecked(feedback),
        });
    };

    const startEditFeedback = async () => {
        try {
            await appointmentContext?.feedbackStartEdit(feedback);
            onEdit();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    };

    const goBackToReSubmit = async () => {
        Modal.confirm({
            title: 'Want to re-submit?',
            content: `All your recordings will be preserved. You will be able to delete/make new recordings. All typed text will be lost if using Ambient Mode. If you want to make some text edits, press "Edit" instead.`,
            onOk: async () => {
                try {
                    await appointmentContext!.goBackToReSubmit();
                    onResubmit();
                } catch (e: any) {
                    notification.error({ message: e.message, duration: 2 });
                    await appointmentContext!.refresh();
                }
            },
        });
    };

    return <>
        <LoadingOverlay spinning={appointmentContext?.loading}>
            <div>
                <div className='mb-10 flex justify-between'>
                    <Text className='fs-20 color-white fw-500'>
                        {user.isSaasDoctor
                            ? "Your recording is successfully processed"
                            : "Your notes are submitted to your scribe for editing."
                        }
                    </Text>

                    <div className='flex align-center justify-end'>
                        <Tag color={statusData.color} className='fs-16'>{statusData.label}</Tag>
                    </div>
                </div>

                <Summary feedback={feedback} />

                <Divider style={{ background: "#ccc" }} />
                <footer className='flex align-center justify-between'>
                    <div className='flex align-center gap-5'>
                        {feedback.status != AppointmentFeedbackStatus.Completed && <>
                            <Button
                                size='large'
                                onClick={goBackToReSubmit}
                                disabled={feedback.isEditing}>
                                {feedback?.isEditing ? 'Taken by the editor' : 'Back'}
                            </Button>
                        </>}
                        <Button
                            size='large'
                            onClick={startEditFeedback}
                            loading={appointmentContext?.loading}>
                            Edit
                        </Button>

                    </div>

                    <div className='flex align-center gap-5'>
                        <Button
                            size='large'
                            onClick={() => outputModal.open(feedback?.id)}>
                            Output
                        </Button>
                        <Button
                            size='large'
                            onClick={() => visitSummaryModal.open(feedback?.id)}>
                            Summary
                        </Button>
                        {user.isSAASUser && <Button size='large'
                            loading={appointmentContext?.loading}
                            style={{
                                background: feedback?.addedToPims ? COLORS.green : COLORS.buttonBg,
                                border: feedback?.addedToPims ? COLORS.green : COLORS.buttonBg
                            }}>
                            <Popconfirm title={feedback?.addedToPims ? 'Remove from PIMS?' : 'Add to PIMS?'}
                                onConfirm={() => appointmentContext.addToPIMS(feedback.addedToPims)}>
                                {feedback?.addedToPims ? 'Added to PIMS' : 'Add to PIMS'}
                            </Popconfirm>
                        </Button>}
                        {feedback?.status === AppointmentFeedbackStatus.Completed
                            ? <Button
                                size='large'
                                disabled>
                                {user.isSAASUser ? 'Completed' : 'Approved'}
                            </Button>
                            : <Button
                                size='large' onClick={approveFeedback}>
                                {user.isSAASUser ? 'Complete' : 'Approve'}
                            </Button>}
                    </div>
                </footer>
            </div>
        </LoadingOverlay>

        <Modal open={outputModal.visible}
            width={850}
            onOk={() => outputModal.close()}
            onCancel={() => outputModal.close()}
            title={"Output"}
            okText="Close"
            cancelButtonProps={{ style: { display: 'none' } }} destroyOnClose={true} closable={true} maskClosable={true}>
            {<FeedbackOutput id={outputModal.data!} />}
        </Modal>

        <Modal open={visitSummaryModal.visible}
            width={850}
            onOk={() => visitSummaryModal.close()}
            onCancel={() => visitSummaryModal.close()}
            title={"Output"}
            okText="Close"
            cancelButtonProps={{ style: { display: 'none' } }} destroyOnClose={true} closable={true} maskClosable={true}>
            {<FeedbackVisitSummary id={visitSummaryModal.data!} />}
        </Modal>
    </>;
});

export default Submitted;

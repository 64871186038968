import { Button, notification, Skeleton, Space } from "antd";
import { TemplateFieldType } from "api/model/Template/TemplateField";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CopiableDiv from "../CopiableDiv/CopiableDiv";
import useApi from "misc/hooks/useApi";
import { AdminFeedbackNoteDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackNoteDto";
import { extractTextWithLineBreaks } from "misc/helpers/extractText";

const FeedbackVisitSummary: React.FC<{ id: number }> = observer(({ id }) => {
    const outputRef = useRef<HTMLDivElement>(null);
    const api = useApi();
    const [feedback, setFeedback] = useState<AdminFeedbackDto | null>(null);

    const fetchFeedback = useCallback(async () => {
        const res = await api.adminFeedback.get(id);
        console.log(id, res);
        if (res) setFeedback(res.data);
        else notification.error({ message: 'Failed to fetch feedback' });
    }, [id]);

    useEffect(() => {
        fetchFeedback();
    }, [fetchFeedback]);

    const copyTextToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const textToCopy = extractTextWithLineBreaks(tempDiv);

        await navigator.clipboard.writeText(textToCopy);
        notification.success({ message: 'Output is copied to clipboard' });

    }, []);

    const copyHtmlToClipboard = useCallback(async (html: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        tempDiv.querySelectorAll('.copiable-button').forEach(button => button.remove());

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
        });
        await navigator.clipboard.write([clipboardItem]);

        notification.success({ message: 'Output is copied to clipboard' });
    }, [outputRef.current]);

    const applySimpleMarkdown = (text:string) => {
        // Split by newlines to create paragraphs
        if (!text) text = 'Summary is not available';
        const paragraphs = text.split(/\n+/).filter(paragraph => paragraph.length > 0);
    
        return paragraphs.map((paragraph, index) => {
            // Split by markdown syntax within each paragraph
            const parts = paragraph.split(/(\*\*.*?\*\*|__.*?__)/g);
            const paragraphContent = parts.map((part, partIndex) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return <b key={partIndex} style={{marginTop: '0.5em'}}>{part.slice(2, -2)}</b>;
                }
                if (part.startsWith('__') && part.endsWith('__')) {
                    return <u key={partIndex}>{part.slice(2, -2)}</u>;
                }
                return part;
            });
    
            // Wrap each paragraph in a <div> tag without margin or padding
            return <div key={index}>{paragraphContent}</div>;
        });
    };
    

    if (!feedback) return <Skeleton active />;

    return <>
        <Space>
            <Button onClick={() => copyHtmlToClipboard(outputRef.current?.innerHTML || '')} style={{ marginBottom: 10 }}>Copy w/ formatting</Button>
            <Button onClick={() => copyTextToClipboard(outputRef.current?.innerHTML || '')} style={{ marginBottom: 10 }}>Copy as text</Button>
        </Space>

        <div ref={outputRef}>
            {applySimpleMarkdown(feedback.visitSummary)}
        </div>
    </>;
});

export default FeedbackVisitSummary;

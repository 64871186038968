import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Radio, message, notification } from 'antd';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import SubmitFeedbackAmbient from './SubmitFeedbackAmbient';
import SubmitFeedbackNotes from './SubmitFeedbackNotes';
import useCurrentUser from 'misc/hooks/useCurrentUser';


type SubmitFeedbackProps = {
    feedback: AppointmentFeedbackClientDto;
    onDelete: () => void;
};

const SubmitFeedback: React.FC<SubmitFeedbackProps> = observer(({ feedback, onDelete }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const user = useCurrentUser();

    const complete = useCallback(async () => {
        await appointmentContext.completeFeedback();
    }, []);

    const saveAppointmentWithAudios = useCallback(async () => {
        const res = await appointmentContext.saveAppintmentWithAudios();
        if (res)
            notification.success({ message: 'Appointment saved successfully.', duration: 2 });
        else
            notification.error({ message: 'Error occured while saving appointment.', duration: 2 });


    }, []);

    const confirmPrecheck = useCallback(async () => {
        if (appointmentContext?.isEmpty) {
            message.warning('No recordings. Please go back and re-record.');
        } else if (appointmentContext?.isRecording) {
            message.warning('Recording in progress. Please stop recording before submit.');
        } else {
            await complete();
        }
    }, [appointmentContext, complete]);

    const setAmbientMode = useCallback(async (isAmbient: boolean) => {
        if (appointmentContext)
            await appointmentContext.setAmbientMode(isAmbient);

    }, [appointmentContext, feedback]);

    return <div className='w-100 mt-20'>
        <div className='flex align-center justify-center w-100'>
            <Radio.Group
                size='large'
                buttonStyle='solid'
                defaultValue={feedback?.ambientMode ? 'ambient' : 'dictation'}
                value={feedback?.ambientMode ? 'ambient' : 'dictation'}
                onChange={async (e) => {
                    await setAmbientMode(e.target.value === 'ambient');
                }}
                disabled={appointmentContext?.isRecording}>
                <Radio.Button value="ambient">Ambient Recording</Radio.Button>
                <Radio.Button value="dictation">Dictation (custom notes)</Radio.Button>
            </Radio.Group>
        </div>

        {feedback?.ambientMode === false
            ? <SubmitFeedbackNotes feedback={feedback} />
            : <SubmitFeedbackAmbient feedback={feedback} />}

        <div className='flex justify-between mt-20'>
            <div className='flex'>
                {appointmentContext?.canBeDeleted && !appointmentContext?.isRecording && (
                    <Popconfirm okType='default'
                        title="Are you sure you want to delete this appointment?"
                        onConfirm={onDelete}
                        okText="Yes"
                        cancelText="No">
                        <Button
                            size='large'
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: '10px' }} />
                    </Popconfirm>
                )}
            </div>

            {user.isVetTech
                ? <Popconfirm okType='default'
                    title="Are you sure you want to submit?"
                    onConfirm={saveAppointmentWithAudios}
                    okText="Yes"
                    cancelText="No"
                    disabled={appointmentContext?.isRecording}>
                    <Button
                        size='large'
                        disabled={appointmentContext?.isRecording}
                        loading={appointmentContext?.savingDraft || appointmentContext?.savingNote}>
                        Save
                    </Button>
                </Popconfirm>
                : <Popconfirm okType='default'
                    title="Are you sure you want to submit?"
                    onConfirm={confirmPrecheck}
                    okText="Yes"
                    cancelText="No"
                    disabled={appointmentContext?.isRecording}>
                    <Button
                        size='large'
                        disabled={appointmentContext?.isRecording}
                        loading={appointmentContext?.savingDraft || appointmentContext?.savingNote}>
                        Submit
                    </Button>
                </Popconfirm>}
        </div>
    </div>;
});

export default SubmitFeedback;
